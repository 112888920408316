.dbdashboard {
    overflow: auto;
    font-family: Arial, sans-serif;
    text-align: center;
    
    position: absolute;
    top: 62px;
    right: 0;
    bottom: 56px;
    left: 0;
    background-color: #fff;
    z-index: 999;
  }
  
  .dbheader {
    background-color: #AFE1B9;
    padding: 0px 10px 0px 10px;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    height: 62px;
  }
  
  .dbmenu-icon {
    font-size: 30px;
    color:#FFAA7B;
    font-weight: bold;
  }
  
  .dbuser-info {
    /* display: flex;
    align-items: center; */
  }
  
  .dbuser-icon {
    background-color: #d9d9d9;
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
  
  .dbusername, .dbuser-id {
    margin-left: 5px;
  }
  
  .dbheader-icons {
    display: flex;
  }
  
  .dbheader-icon {
    margin-left: 10px;
    font-size: 24px;
  }
  
  .dbadvertisement {
    /* background-color: #d3d3d3; */
    background-image: url(./img/c0c388301abfb4e87c79539c8c65fbca.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    /* padding: 60px; */
    height: 56.25vw;
    width: 100vw;
    margin-top: 20px;
  }
  
  .dbnotice {
    margin-top: 5px;
    display: flex;
  }
  
  .dbpost {
    margin-top: 10px;
  }
  
  .dbpost-image {
    background-color: #d3d3d3;
    padding: 40px;
    margin-bottom: 10px;
  }
  
  .dbpost-image-1 {
    /* background-color: #d3d3d3; */
    background-image: url(./img/247e24ae649a000189ae802ff35a0cc2.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 52vw;
    width: 100vw;
  }

  .dbpost-image-2 {
    /* background-color: #d3d3d3; */
    background-image: url(./img/VinGrcollaboration.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 85vw;
    width: 100vw;
  }

  .dbpost-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dbpost-icon {
    margin-right: 10px;
  }
  
  .dbfooter {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  .dbfooter {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
 }
  
  .dbfooter-icon {
    font-size: 24px;
  }

  .MuiBottomNavigation-root{
    height:54px !important;
  }

  .MuiBottomNavigationAction-root {
    min-width: 0px !important;
  }

  .MuiDrawer-paper{
    background-color: white;
    width: 100vw;
  }
  