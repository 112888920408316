.cbcarebiz-chat {
    font-family: Arial, sans-serif;
    color: #FFAA7B;
    text-align: center;
    padding: 0px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    z-index: 999;
  }
  
  .cbheader {
    background-color: #AFE1B9;
    /* padding: 10px; */
    display: flex;
    align-items: center;
    justify-content: center !important;
  }
  
  .cbheader .icon {
    margin-right: 10px;
  }
  
  .cbform-container {
    margin-top: 20px;
  }
  
  .cbform-group {
    margin-bottom: 20px;
  }
  
  .cbform-group label {
    color: #AFE1B9;
    font-weight: bold;
    display: block;
    font-size: 25px;
  }
  
  .cbform-group input {
    width: calc(100% - 40px);
    padding: 10px;
    margin:10px;
    margin-bottom: 0;
    border: 1px solid #FFAA7B;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .cbforgot-password {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    margin-right: 10px;
  }
  
  .cbforgot-password a {
    color: #FFAA7B;
    text-decoration: none;
  }
  
  .cblogin-btn, .cbcreate-account-btn {
    display: block;
    width: calc(100% - 20px);
    /* width:100%; */
    margin: 10px auto;
    font-weight: bold;
    padding: 10px;
    border: none;
    border-radius: 100px;
    background-color: #AFE1B9;
    color: #FFAA7B;
    font-size: 25px;
    cursor: pointer;
  }
  
  .cbfooter {
    margin-top: 20px;
  }
  
  .cbfooter a {
    color: #FFAA7B;
    text-decoration: none;
    display: block;
    font-size: 13px;
    font-weight: bold;
    margin-left: 10px;
  }
  
  .cbfooter .logo {
    margin-top: 10px;
    font-size: 14px;
  }
  